<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="68px">
			<el-form-item class="el_form_item" label="事件编号">
				<el-input class="el_input" v-model="form.truck_tord_event_num" placeholder="运单事件编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="运单编号">
				<el-input class="el_input" v-model="form.truck_tord_num" placeholder="货源运单编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="组别编号">
				<el-input class="el_input" v-model="form.group1_num" placeholder="运单组别编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="货物名称">
				<el-input class="el_input" v-model="form.cargo_name" placeholder="货物名称搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="装货地">
				<el-cascader class="el_input" v-model="form.case_cities" @change="ser_para_case_cities_change" placeholder="装货地省市区" :options="ser_para.cities" filterable></el-cascader>
			</el-form-item>
			<el-form-item class="el_form_item" label="到货地">
				<el-cascader class="el_input" v-model="form.aim_cities" @change="ser_para_aim_cities_change" placeholder="到货地省市区" :options="ser_para.cities" filterable></el-cascader>
			</el-form-item>
			<el-form-item class="el_form_item" label="收款人手机">
				<el-input class="el_input" v-model="form.truck_owner_tel" placeholder="收款人手机" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="司机手机">
				<el-input class="el_input" v-model="form.driver_tel" placeholder="司机手机" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="车牌号">
				<el-input class="el_input" v-model="form.truck_plate_num" placeholder="货源运单编号" clearable></el-input>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="cargo_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="运单编号/货物名称/类型" width="220px">
					<template slot-scope="scope">
						<div class="tab_line_item">(编号){{scope.row.truck_tord_num}}</div>
						<div class="tab_line_item">(名称){{scope.row.cargo_name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="重量/体积" width="118px">
					<template slot-scope="scope">
						<div class="tab_line_item">(重量){{scope.row.cargo_weight==0?"未设置":scope.row.cargo_weight+"吨"}}</div>
						<div class="tab_line_item">(体积){{scope.row.cargo_volume==0?"未设置":scope.row.cargo_volume+"方"}}</div>
					</template>
				</el-table-column>
				<el-table-column label="装货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">(省市区) {{scope.row.case_prov}} / {{scope.row.case_city}} / {{scope.row.case_county}}</div>
						<div class="tab_line_item">(详细) {{scope.row.case_addr?scope.row.case_addr:'无'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="卸货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">(省市区) {{scope.row.aim_prov}} / {{scope.row.aim_city}} / {{scope.row.aim_county}}</div>
						<div class="tab_line_item">(详细) {{scope.row.aim_addr?scope.row.aim_addr:'无'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="运费/服务费" width="135px">
					<template slot-scope="scope">
						<div class="tab_line_item">(总运费) {{scope.row.freight_total}}元</div>
						<div class="tab_line_item">(已支付) {{scope.row.freight_payed_total}}元</div>
						<div class="tab_line_item">(服务费) {{scope.row.extra_service_charge_total}}元</div>
						<div class="tab_line_item">(已支付) {{scope.row.extra_service_charge_payed_total}}元</div>
					</template>
				</el-table-column>
				<el-table-column label="收款人/司机" width="160px">
					<template slot-scope="scope">
						<div class="tab_line_item">(收款人姓名) {{scope.row.truck_owner_info.name}}</div>
						<div class="tab_line_item">(收款人电话) {{scope.row.truck_owner_info.tel}}</div>
						<div class="tab_line_item">(司机姓名) {{scope.row.driver_info.name}}</div>
						<div class="tab_line_item">(司机电话) {{scope.row.driver_info.tel}}</div>
					</template>
				</el-table-column>
				<el-table-column label="车辆信息" width="170px">
					<template slot-scope="scope">
						<div class="tab_line_item">(车牌) {{scope.row.truck_plate_num}}</div>
						<div class="tab_line_item">(类型) {{scope.row.truck_info.truck_type_name}}/{{scope.row.truck_info.truck_type_name2}}</div>
						<div class="tab_line_item">(尺寸) {{scope.row.truck_info.truck_length}}/{{scope.row.truck_info.truck_width}}/{{scope.row.truck_info.truck_height}}</div>
						<div class="tab_line_item">(额载) {{scope.row.truck_info.truck_weight}}吨/{{scope.row.truck_info.truck_volume}}方</div>
					</template>
				</el-table-column>
				<el-table-column label="时间信息" width="172px">
					<template slot-scope="scope">
						<div class="tab_line_item">(接单){{scope.row.creat_time_text}}</div>
						<div class="tab_line_item">(发车){{scope.row.start_time_text}}</div>
						<div class="tab_line_item">(到货){{scope.row.arrived_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="发票状态" width="107px">
					<template slot-scope="scope">
						<div class="tab_line_item">(状态) {{scope.row.invoice_status_text}}</div>
						<div >(操作)
							<el-button v-if="scope.row.invoice_status==1" class="btn_left" @click="invoice_apply(scope.row)" size="mini" type="text">申请开票</el-button>
							<el-button v-if="scope.row.invoice_status==2" class="btn_left" @click="invoice_cancel(scope.row)" size="mini" type="text">撤销开票</el-button>
							<el-button v-if="scope.row.invoice_status==4" class="btn_left" @click="invoice_apply(scope.row)" size="mini" type="text">重申开票</el-button>
						</div>
					</template>
				</el-table-column>

				<!-- 操作行 -->
				<el-table-column label="操作" width="80px">
					<template slot-scope="scope">
						<el-button class="btn_left" @click="show_path(scope.row)" size="mini" type="text">轨迹</el-button>
						<el-button class="btn_left" @click="tord_pay(scope.row)" size="mini" type="text" style="margin-left:5px">支付</el-button>
						<el-button class="btn_left" @click="tord_edit_open_view(scope.$index)" size="mini" type="text">修改</el-button>
						<el-button class="btn_left" @click="tord_cancel(scope.row)" size="mini" type="text" style="margin-left:5px">删除</el-button>
						<el-button class="btn_left" @click="voucher_show(scope.row.truck_tord_num)" size="mini" type="text">凭证</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>


		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>


		<!-- 运单修改 -->
		<el-dialog title="运单修改" top="1vh" width="600px" :visible.sync="tord_edit_para.is_show">
			<el-form label-position="left" label-width="75px">
				<el-form-item label="收款人信息">
					<el-col :span="8">
						<el-input v-model="tord_edit_para.data.truck_owner_info.tel" clearable></el-input>
					</el-col>
					<el-col :span="16">
						<el-input 
							v-if="user_list[tord_edit_para.data.truck_owner_info.tel]"
							v-model="user_list[tord_edit_para.data.truck_owner_info.tel].user_name" 
							disabled
						>
							<el-button @click="user_ser(tord_edit_para.data.truck_owner_info.tel)" type="success" slot="append" style="width:80px">搜索</el-button>
						</el-input>
						<el-input v-else disabled>
							<el-button @click="user_ser(tord_edit_para.data.truck_owner_info.tel)" type="success" slot="append" style="width:80px">搜索</el-button>
						</el-input>
					</el-col>
				</el-form-item>
				<el-form-item label="司机信息">
					<el-col :span="8">
						<el-input v-model="tord_edit_para.data.driver_info.tel" clearable></el-input>
					</el-col>
					<el-col :span="16">
						<el-input
							v-if="user_list[tord_edit_para.data.driver_info.tel]"
							v-model="user_list[tord_edit_para.data.driver_info.tel].user_name" 
							disabled
						>
							<el-button @click="user_ser(tord_edit_para.data.driver_info.tel)" type="success"
								slot="append" style="width:80px">搜索</el-button>
						</el-input>
						<el-input v-else disabled>
							<el-button @click="user_ser(tord_edit_para.data.driver_info.tel)" type="success"
								slot="append" style="width:80px">搜索</el-button>
						</el-input>
					</el-col>
				</el-form-item>
				<el-form-item label="货车信息">
					<el-col :span="8">
						<el-input v-model="tord_edit_para.data.truck_plate_num" clearable></el-input>
					</el-col>
					<el-col :span="16">
						<el-input 
							v-model="truck_list[tord_edit_para.data.truck_plate_num]" 
							disabled
						>
							<el-button @click="truck_ser(tord_edit_para.data.truck_plate_num)" type="success" slot="append" style="width:80px">搜索</el-button>
						</el-input>
					</el-col>
				</el-form-item>
				<el-form-item label="装货地 和 装货时间">
					<el-cascader 
						v-model="tord_edit_para.data.case_codes" 
						placeholder="装货地省市区" 
						:options="ser_para.cities"
						style="width: 100%;" filterable
					></el-cascader>
					<el-input 
						v-model="tord_edit_para.data.case_addr" 
						placeholder="装货地详细地址"
						style="margin-top: 5px;" clearable
					></el-input>
					<el-date-picker 
						v-model="tord_edit_para.data.start_time_obj" 
						type="datetime"
						style="width: 100%;margin-top: 5px;" filterable
					></el-date-picker>
				</el-form-item>
				<el-form-item label="到货地 和 到货时间">
					<el-cascader 
						v-model="tord_edit_para.data.aim_codes" 
						placeholder="装货地省市区" 
						:options="ser_para.cities"
						style="width: 100%;" filterable
					></el-cascader>
					<el-input 
						v-model="tord_edit_para.data.aim_addr" 
						placeholder="装货地详细地址" 
						style="margin-top: 5px;" clearable
					></el-input>
					<el-date-picker 
						v-model="tord_edit_para.data.arrived_time_obj" 
						type="datetime"
						style="width: 100%;margin-top: 5px;" filterable
					></el-date-picker>
				</el-form-item>
				<el-form-item label="货物名称 重量/体积">
					<el-input v-model="tord_edit_para.data.cargo_name" clearable></el-input>
					<el-col :span="12" style="margin-top: 5px;">
						<el-input v-model="tord_edit_para.data.cargo_weight" type="number" clearable>
							<el-button slot="append" style="width:60px">吨</el-button>
						</el-input>
					</el-col>
					<el-col :span="12" style="margin-top: 5px;">
						<el-input v-model="tord_edit_para.data.cargo_volume" type="number" clearable>
							<el-button slot="append" style="width:60px">方</el-button>
						</el-input>
					</el-col>
				</el-form-item>
				<el-form-item label="组号/备注/运费">
					<el-input v-model="tord_edit_para.data.group1_num" placeholder="请输入组号" clearable></el-input>
					<el-input v-model="tord_edit_para.data.mark" placeholder="请输入备注" style="margin-top: 5px;" clearable>
					</el-input>
					<el-input v-model="tord_edit_para.data.freight_total" placeholder="请输入运费" type="number"
						style="margin-top: 5px;" clearable>
						<el-button slot="append" style="width:60px">元</el-button>
					</el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" style="width:90px" @click="tord_edit_confirm">提交</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>


		<!-- 凭证界面 -->
		<voucher 
			:truck_tord_num="voucher.truck_tord_num" 
			:is_show="voucher.is_show" 
		>
		</voucher>

	</div>
</template>

<script>
	import {regionData,CodeToText,TextToCode} from 'element-china-area-data'
	import voucher from '../pub/voucher.vue'
	export default {
		components: {
			voucher
		},
		data() {
			return {

				//搜索条件参数
				ser_para:{
					cities:regionData,//省市区数据
				},

				//搜索条件
				form: {		
					truck_tord_event_num:'',//事件编号		
					truck_cargo_num:'',//货源编号
					group1_num:'',//组别编号
					truck_owner_tel:'',//收款人手机号
					driver_tel:'',//司机手机号
					truck_plate_num:'',//车牌号
					cargo_name:'',//货物名称
					case_prov:'',//装货地省份
					case_city:'',//装货地城市
					case_county:'',//装货地县区
					aim_prov:'',//到货地省份
					aim_city:'',//到货地城市
					aim_county:'',//到货地县区

					case_cities:null,//装货地省市县
					aim_cities:null,//到货地省市县
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//运单修改
				tord_edit_para: {
					index: -1,
					is_show: false,
					data: {
						case_info: {},
						start_time: {},
						arrived_time: {},
						aim_info: {},
						driver_info:{},
						truck_owner_info:{},
						truck_info:{}
					},
				},

				//凭证相关
				voucher:{
					is_show:0,
					truck_tord_num:''
				},

				//相关用户列表
				user_list: {},

				//货车列表
				truck_list: {},
			}
		},
		created() {
			
			//取出参数
			let query=this.$route.query;

			//置入参数
			if(query.truck_tord_event_num){
				this.form.truck_tord_event_num=query.truck_tord_event_num
			}

			//读取货源
			this.get_page_data()
		},
		methods: {

			//支付
			tord_pay(){

			},

			//显示轨迹
			show_path(){

			},
			
			//凭证相关操作
			voucher_show(truck_tord_num){//显示凭证

				this.voucher.is_show++;
				this.voucher.truck_tord_num=truck_tord_num
			},

			//撤销申请开票
			invoice_cancel(item){
				
				//询问
				this.$my.other.confirm({
					content:"点击确定撤销开票申请",
					confirm:()=>{
						
						//调用接口
						this.$my.net.req({
							data:{
								mod:'truck_tord_after_time',
								ctr:'tord_invoice_cancel_by_shipper',
								truck_tord_num:item.truck_tord_num,
							},
							callback:(data)=>{
								
								//刷新本页
								this.get_page_data()
							}
						})
					}
				})
			},

			//申请开票
			invoice_apply(item){

				//暂时给个弹窗 以后有空改成开票预览
				this.$my.other.confirm({
					content:"运费:"+item.freight_total+"元,服务费:"+item.extra_service_charge_total+"元",
					confirm:()=>{
						
						//调用接口
						this.$my.net.req({
							data:{
								mod:'truck_tord_after_time',
								ctr:'tord_invoice_apply_by_shipper',
								truck_tord_num:item.truck_tord_num,
								invoice_status:item.invoice_status
							},
							callback:(data)=>{
								
								//刷新本页
								this.get_page_data()
							}
						})
					}
				})
			},

			//取消运单
			tord_cancel(item){
				
				//提示
				this.$my.other.confirm({
					content:"您的操作是'删除运单',请确认",
					confirm:()=>{
			
						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_after_time',
								ctr:'tord_check_pass_del_by_shipper',
								truck_tord_num:item.truck_tord_num,
							},
							callback:(data)=>{
			
								//刷新本页
								this.get_page_data()
							},
						});
					}
				});
			},

			//查找货车
			truck_ser(truck_plate_num){

				//已经查找过
				if (this.truck_list[truck_plate_num]) {

					this.$my.other.msg({
						type: 'warning',
						str: '此车辆已经查找过,具体信息以表格内为准'
					})
					return;
				}

				//置入待查找组
				this.$set(this.truck_list,truck_plate_num,"")

				//调用接口查询数据
				this.$my.net.req({
					data: {
						mod: 'truck',
						ctr: 'truck_list_api',
						truck_plate_nums: [truck_plate_num],
						status: 2,
					},
					callback: (data) => {

						//报告结果
						this.$my.other.msg({
							type: 'success',
							str: '车辆信息查找完成'
						})

						//置入
						for (var truck of data.list) {

							this.truck_list[truck_plate_num]=
								truck.truck_type_name+"/"+
								truck.truck_type_name2+"/"+
								truck.truck_weight
						}
					}
				})
			},


			//搜索用户
			user_ser(tel) {

				//已经查找过
				if (this.user_list[tel]) {

					this.$my.other.msg({
						type: 'warning',
						str: '此用户已经查找过,具体信息以表格内为准'
					})
					return;
				}

				//置入待查找组
				this.$set(this.user_list,tel,{
					user_num: '',
					user_name: '',
				})

				//调用接口查询数据
				this.$my.net.req({
					data: {
						mod: 'app_user',
						ctr: 'user_list_by_app_user',
						tels: [tel],
						status: 1,
						real_status: 2,
					},
					callback: (data) => {

						//报告结果
						this.$my.other.msg({
							type: 'success',
							str: '收款人信息和司机信息查找完成'
						})

						//置入用户搜索结果
						for (var user of data.list) {

							this.user_list[user.tel]={
								user_num:user.user_num,
								user_name:user.name,
							}
						}
					}
				})
			},


			//运单修改
			tord_edit_open_view(index) {
				this.tord_edit_para.index = index
				this.tord_edit_para.is_show = true;
				this.tord_edit_para.data = JSON.parse(JSON.stringify(this.list.data[index]))
			},
			tord_edit_confirm() {

				//取出修改后的数据
				let new_tord_data = this.tord_edit_para.data
				let old_freight_total = this.list.data[this.tord_edit_para.index].freight_total

				//获取发货城市数据
				new_tord_data.case_prov=CodeToText[new_tord_data.case_codes[0]]
				new_tord_data.case_city=CodeToText[new_tord_data.case_codes[1]]
				new_tord_data.case_county=CodeToText[new_tord_data.case_codes[2]]

				//获取到货城市数据
				new_tord_data.aim_prov=CodeToText[new_tord_data.aim_codes[0]]
				new_tord_data.aim_city=CodeToText[new_tord_data.aim_codes[1]]
				new_tord_data.aim_county=CodeToText[new_tord_data.aim_codes[2]]
				
				//获取新的时间数据
				let new_start_timestamp=new Date(new_tord_data.start_time_obj).getTime()
				let new_arrived_timestamp=new Date(new_tord_data.arrived_time_obj).getTime()
				if(new_start_timestamp>=new_arrived_timestamp){
					this.$my.other.msg({
						type: 'warning',
						str: '没有需要修改的数据,请直接关闭页面'
					})
					return;
				}else{
					new_tord_data.start_time=(new_start_timestamp/1000).toFixed(0)
					new_tord_data.arrived_time=(new_arrived_timestamp/1000).toFixed(0)
				}

				//是否修改运费
				if(new_tord_data.freight_total!=old_freight_total){
					var has_change_freight_total=1
				}else{
					var has_change_freight_total=2
				}
				
				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_after_time',
						ctr:'tord_check_pass_edit_by_shipper',
						has_change_freight_total:has_change_freight_total,
						tord_data:new_tord_data
					},
					callback:(data)=>{

						this.tord_edit_para.is_show = false;

						this.get_page_data()
					}
				})
			},

			
			//清空查询条件
			ser_para_clear(){
				this.form={
					truck_cargo_num:'',//货源编号
					driver_tel:'',//收款人手机号
					truck_plate_num:'',//车牌号
					cargo_name:'',//货物名称
					case_prov:'',//装货地省份
					case_city:'',//装货地城市
					case_county:'',//装货地县区
					aim_prov:'',//到货地省份
					aim_city:'',//到货地城市
					aim_county:'',//到货地县区
					case_cities:null,//装货地省市县
					aim_cities:null,//到货地省市县
				}
				this.get_page_data()
			},

			//发货地省市县选择器
			ser_para_case_cities_change(event){
				this.form.case_prov=CodeToText[event[0]];
				this.form.case_city=CodeToText[event[1]];
				this.form.case_county=CodeToText[event[2]];
			},

			//到货地省市县选择器
			ser_para_aim_cities_change(event){
				this.form.aim_prov=CodeToText[event[0]];
				this.form.aim_city=CodeToText[event[1]];
				this.form.aim_county=CodeToText[event[2]];
			},

			//搜索
			cargo_ser(){
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_after_time',
						ctr:'tord_check_pass_list_by_shipper',
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//成交时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);
							
							//发车时间
							item.start_time_text=this.$my.other.totime(item.start_time);
							item.start_time_obj=new Date(item.start_time * 1000)
							
							//到货时间
							item.arrived_time_text=this.$my.other.totime(item.arrived_time);
							item.arrived_time_obj=new Date(item.arrived_time * 1000)
							
							//发车地
							item.case_codes=[
								TextToCode[item.case_prov].code,
								TextToCode[item.case_prov][item.case_city].code,
								TextToCode[item.case_prov][item.case_city][item.case_county].code,
							];

							//开票状态
							switch(item.invoice_status){
								case '1':item.invoice_status_text="未申请";break;
								case '2':item.invoice_status_text="已申请";break;
								case '3':item.invoice_status_text="已开出";break;
								case '4':item.invoice_status_text="被驳回";break;
							}

							//到货地
							item.aim_codes=[
								TextToCode[item.aim_prov].code,
								TextToCode[item.aim_prov][item.aim_city].code,
								TextToCode[item.aim_prov][item.aim_city][item.aim_county].code,
							];

							//收集收款人信息
							this.user_list[item.truck_owner_info.tel]={
								user_num:item.truck_owner_info.user_num,
								user_name:item.truck_owner_info.name,
							};

							//收集司机信息
							this.user_list[item.driver_info.tel]={
								user_num:item.driver_info.user_num,
								user_name:item.driver_info.name,
							};

							//收集货车信息
							this.truck_list[item.truck_info.truck_plate_num]=
								item.truck_info.truck_type_name+"/"+
								item.truck_info.truck_type_name2+"/"+
								item.truck_info.truck_weight
						}

						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 134px);
	}
	.el_form_item{
		width: 275px;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>